.nav-link {
  &.active {
    text-decoration: underline;
  }
}

#header {
  font-family: 'Lato';
  font-weight: 100;
  font-size: 1.35rem;
  padding: 0 20px;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 1em;
  background-color: transparent;
  position: absolute;

  @include media-breakpoint-down(md) {
    font-size: 1.1rem;
  }
}

.header_impressum {
  position: absolute;
  top: 0;
  width: 100%;
}

.galerie_img {
  width: 90%;
  aspect-ratio: 4 / 5;
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: 0px;
}

@import './fonts.scss';
@import url('https://fonts.googleapis.com/css2?family=Istok+Web:ital,wght@0,400;0,700;1,400;1,700&family=Lato:ital,wght@0,100;0,300;1,100;1,300&family=Water+Brush&display=swap');
@import './overwrites.scss';

@import '~bootstrap/scss/bootstrap';
@import 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css';

@import './header.scss';
@import './footer.scss';

// #aedobd
// #7aa59d
// #628d85
// #aa8187
// #aa6674

.map {
  aspect-ratio: 2 / 1;
  width: 50%;
}

.main-title {
  padding: 160px 0px;
  letter-spacing: 1.3px;

  .heading {
    font-size: 2rem;
    line-height: 2;
    color: white;

    @include media-breakpoint-up(md) {
      font-size: 4rem;
      line-height: 1.5;
    }
  }
  .subHeading {
    font-size: 4rem;
    font-family: 'Jannet';
    color: $primary;

    @include media-breakpoint-up(md) {
      font-size: 4rem;
    }
  }
}

.signature {
  font-size: 1.5rem;
  font-family: 'aphrodite';
}

.chapter {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: 'aphrodite';
  color: $primary;;
}

.subHeading {
  font-size: 1.5rem;
}

.heading {
  font-size: 1.5rem;
  font-family: 'aphrodite';
}


.background-img {
  background-image: url('../img/FrontImage.jpeg');
  background-size: cover;
  background-position: 45% 50%;
}

.overlay {
  background: rgba(102, 65, 16, 0.25);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bg-grey {
  background-color: #f6f6f6;
}

.fs-md-4 {
  @include media-breakpoint-up(md) {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
}

.fs-md-5 {
  @include media-breakpoint-up(md) {
    font-size: 1.25rem !important;
  }
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}

.galerie_img {
  width: 95%;
  aspect-ratio: 4 / 3;
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: 0px;
}

.carousel-indicators,
.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}

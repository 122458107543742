@font-face {
  font-family: 'Jannet';
  src: url('../fonts/Jannet.woff2') format('woff2'),
    url('../fonts/Jannet.woff') format('woff'),
    url('../fonts/Jannet.ttf') format('truetype');
}
@font-face {
  font-family: 'aphrodite';
  src: url('../fonts/aphrodite-slim-text.ttf') format('woff2'),
    url('../fonts/aphrodite-slim-text.woff') format('woff'),
    url('../fonts/aphrodite-slim-text.ttf') format('truetype');
}


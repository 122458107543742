/* font */
// $font-family-base: 'Tangerine', cursive;
$font-family-base: 'Istok Web', sans-serif;
$font-size-base: 1rem;
$line-height-base: 2;

/* color */
$primary: rgb(255, 206, 143);
$body-color: #909692;

$enable-validation-icons: false;
